import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import moment from 'moment';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import CmsPageSectionFooter from '../../../shared/cmsPage/components/CmsPageSectionFooter';
import useItemEditActions from '../../../shared/cmsPage/hooks/useItemEditActions';
import Select from '../../../shared/form/components/Select';
import { useCmsForm } from '../../../shared/form/hooks/useCmsForm';
import ActionButton from '../../../shared/muiButtons/muiButton';
import { selectHasPrivileges } from '../../../store/rootSelectors';
import { upsertTravelConfirmation } from '../../api/travelApi';
import { ItrsSessionConstants } from '../../definitions';
import ItrsTravelSegmentForm from './ItrsTravelSegmentForm';

const Heading = ({ children, subheading = false, sx = {}, ...rest }) => (
  <Typography
    variant={subheading ? 'subtitle1' : 'h6'}
    sx={(theme) => ({
      backgroundColor: subheading ? theme.palette.bootstrap.grey : theme.palette.bootstrap.blue,
      color: 'white',
      padding: theme.spacing(1, 1),
      ...sx,
    })}
    {...rest}
  >
    {children}
  </Typography>
);

const Detail = ({ label, value }) => (
  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
    <Box>
      <Typography variant="body1" sx={{ fontWeight: 'bold' }} gutterBottom>
        {`${label}:`}
      </Typography>
    </Box>
    <Box sx={{ pl: '1rem' }}>
      <Typography variant="body1" gutterBottom>
        {value}
      </Typography>
    </Box>
  </Box>
);

//statuses: Sequelize.ENUM('none', 'submitted', 'confirmed', 'cancelled'),
const statuses = [
  { label: 'None', value: 'none' },
  { label: 'Submitted', value: 'submitted' },
  { label: 'Confirmed', value: 'confirmed' },
  { label: 'Cancelled', value: 'cancelled' },
];

const TravelConfirmSection = ({ travelConfirmation, theme, travelRequestId }) => {
  const [showConfirmView, setShowConfirmView] = useState(true);
  const form = useCmsForm(travelConfirmation);
  const hasItrsTravelPrivileges = useSelector(selectHasPrivileges('itrs.travel'));
  const { setValue } = form;
  const actions = useItemEditActions(
    upsertTravelConfirmation(travelRequestId),
    form,
    ItrsSessionConstants,
    travelRequestId,
    '',
    { refreshPageParams: { hardRefresh: true }, cancelOverride: () => setShowConfirmView(true) }
  );

  const reloadForm = () => {
    setValue('status', travelConfirmation.status);
    setValue('travelToAirport', travelConfirmation.travelTo.Airport);
    setValue('travelToAirline', travelConfirmation.travelTo.Airline);
    setValue('travelToFlight', travelConfirmation.travelTo.Flight);
    setValue('travelToDepartDate', travelConfirmation.travelTo.DepartDate);
    setValue('travelToArrivalDate', travelConfirmation.travelTo.ArrivalDate);
    setValue('travelToConfirmation', travelConfirmation.travelTo.Confirmation);
    setValue('departFromAirport', travelConfirmation.departFrom.Airport);
    setValue('departFromAirline', travelConfirmation.departFrom.Airline);
    setValue('departFromFlight', travelConfirmation.departFrom.Flight);
    setValue('departFromDepartDate', travelConfirmation.departFrom.DepartDate);
    setValue('departFromArrivalDate', travelConfirmation.departFrom.ArrivalDate);
    setValue('departFromConfirmation', travelConfirmation.departFrom.Confirmation);
  };

  const travelToAirport = travelConfirmation?.travelTo?.Airport;
  const travelToAirline = travelConfirmation?.travelTo?.Airline;
  const travelToFlight = travelConfirmation?.travelTo?.Flight;
  const travelToDepartDate = travelConfirmation?.travelTo?.DepartDate;
  //const travelToArrivalDate = travelConfirmation?.travelTo?.ArrivalDate;
  const travelToConfirmation = travelConfirmation?.travelTo?.Confirmation;

  const departFromAirport = travelConfirmation?.departFrom?.Airport;
  const departFromAirline = travelConfirmation?.departFrom?.Airline;
  const departFromFlight = travelConfirmation?.departFrom?.Flight;
  const departFromDepartDate = travelConfirmation?.departFrom?.DepartDate;
  //const departFromArrivalDate = travelConfirmation?.departFrom?.ArrivalDate;
  const departFromConfirmation = travelConfirmation?.departFrom?.Confirmation;

  const bookedBy = travelConfirmation?.bookerEmail;

  return (
    <Box sx={{ my: theme.spacing(3) }}>
      <Heading gutterBottom>Traveller Confirmation</Heading>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          pt: '1rem',
        }}
      >
        {showConfirmView && hasItrsTravelPrivileges && (
          <ActionButton
            variant="contained"
            type="button"
            label={`Enter Travel Confirmation`}
            onClick={() => {
              if (travelConfirmation) {
                reloadForm();
              } else {
                form.reset();
              }
              setShowConfirmView(false);
            }}
          />
        )}
      </Box>

      {showConfirmView && travelConfirmation && (
        <Box sx={{ my: theme.spacing(3) }}>
          <Detail label="Departing From (Airport)" value={travelToAirport} />
          <Detail
            label="Departing Flight"
            value={`${travelToAirline} Flight ${travelToFlight} (Confirmation No. ${travelToConfirmation})`}
          />
          <Detail
            label="Departing Date"
            value={moment(travelToDepartDate).format('dddd, MMMM DD, YYYY - hh:mm A')}
          />
          <Detail label="Returning From (Airport)" value={departFromAirport} />
          <Detail
            label="Departing Flight"
            value={`${departFromAirline} Flight ${departFromFlight} (Confirmation No. ${departFromConfirmation})`}
          />
          <Detail
            label="Departing Date"
            value={moment(departFromDepartDate).format('dddd, MMMM DD, YYYY - hh:mm A')}
          />
          <Detail label="Booked By" value={bookedBy} />
        </Box>
      )}

      {!showConfirmView && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Box
            sx={{
              width: '612px',
            }}
          >
            <Select
              labelKey="label"
              label={<span style={{ paddingInlineStart: '1rem' }}>Status</span>}
              options={statuses}
              formContext={form}
              placeholder={'Status'}
              fieldName="status"
              type="select"
              valueKey={'value'}
              value={''}
              fieldWidth="short"
              fontWeight={500}
              findValueInOptions={true}
              formFieldOptions={{
                required: 'This field is required.',
              }}
              variant="outlined"
            />
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'stretch',
                justifyContent: 'space-between',
              }}
            >
              <ItrsTravelSegmentForm
                form={form}
                theme={theme}
                prefix="travelTo"
                heading="Travel to Training"
              />
              <div
                style={{
                  width: '1px',
                  backgroundColor: '#EEEEEE',
                  minHeight: '1px',
                }}
              />
              <ItrsTravelSegmentForm
                form={form}
                theme={theme}
                prefix="departFrom"
                heading="Depart from Training"
              />
            </Box>

            <div className="row module-footer">
              <CmsPageSectionFooter actions={actions} />
            </div>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default TravelConfirmSection;
