import {
  Box,
  Button,
  Divider,
  FormControlLabel,
  Modal,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectHasPrivileges } from '../../../store/rootSelectors';

const CancelEnrollmentModal = ({ open, onClose, context, onConfirm }) => {
  const [reason, setReason] = useState('');
  const [notes, setNotes] = useState('');
  const hasCancelEnrollment = useSelector(selectHasPrivileges('itrs.cancelEnrollment'));

  const handleCancelEnrollment = () => {
    onConfirm(reason, notes);
  };

  const handleClose = () => {
    setReason('');
    setNotes('');
    onClose();
  };

  return (
    <Modal open={open} onClose={handleClose} aria-labelledby="cancel-enrollment-title">
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          width: '600px',
          borderRadius: 2,
          boxShadow: 24,
          p: 3,
        }}
      >
        <Typography id="cancel-enrollment-title" variant="h6" component="h2" gutterBottom>
          Cancel Enrollment
        </Typography>
        <Divider sx={{ my: 2 }} />
        <Typography variant="body1" gutterBottom>
          Are you sure you want to cancel the enrollment for {context?.name}?
        </Typography>

        <Typography variant="body2" color="textSecondary" sx={{ mb: 1 }}>
          Reason for Cancellation / Action to Take *
        </Typography>
        <RadioGroup value={reason} onChange={(e) => setReason(e.target.value)}>
          <FormControlLabel value="Unable to Attend" control={<Radio />} label="Unable to Attend" />
          <FormControlLabel value="No Show" control={<Radio />} label="No Show" />
          <FormControlLabel value="Did Not Complete" control={<Radio />} label="Did Not Complete" />
          {hasCancelEnrollment && (
            <>
              {/* <FormControlLabel
                value="resetToSaved"
                control={<Radio />}
                label={
                  <>
                    <span>{`Reset Attendee to 'Saved' `}</span>
                    <Typography component="span" color="textSecondary">
                      {`Administrators only`}
                    </Typography>
                  </>
                }
              /> */}
              <FormControlLabel
                value="unenrollCompletely"
                control={<Radio />}
                label={
                  <>
                    <span>{`Unenroll Attendee Completely `}</span>
                    <Typography component="span" color="textSecondary">
                      ({`Administrators only - cannot be undone`})
                    </Typography>
                  </>
                }
              />
            </>
          )}
        </RadioGroup>
        <TextField
          fullWidth
          multiline
          rows={3}
          label="Notes / Comments"
          variant="outlined"
          margin="normal"
          value={notes}
          onChange={(e) => setNotes(e.target.value)}
          placeholder="User is not eligible for enrollment due to a missing prerequisite."
        />
        <Box display="flex" justifyContent="flex-start" mt={3}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleCancelEnrollment}
            disabled={!reason}
            sx={{ mr: 2 }}
          >
            Cancel Enrollment
          </Button>
          <Button variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default CancelEnrollmentModal;
