import React, { useEffect, useMemo } from 'react';
import FormCheckBox from '../../shared/form/components/FormCheckBox';
import FormDateOnly from '../../shared/form/components/FormDateOnly';
import FormDate from '../../shared/form/components/FormDate';
import FormInput from '../../shared/form/components/FormInput';
import RadioButtonGroup from '../../shared/form/components/FormRadioButtonGroup';
import FormWysiwyg from '../../shared/form/components/FormWysiwyg';
import FormCheckBoxGroup from '../../shared/form/components/FormCheckBoxGroup';
import MultiSelect from '../../shared/form/components/MultiSelect';
import api from '../api/itrsApi';
import Divider from '@mui/material/Divider';
import { toNumber } from 'lodash';
import Box from '@mui/material/Box';

const SessionMulti = ({ placeholder, form, label, req, isSingleSelect = true, ...props }) => (
  <MultiSelect
    label={label}
    fieldWidth={500}
    fontWeight={500}
    labelColor="#949494"
    formContext={form}
    placeholder={placeholder || `Select ${label}`}
    valueKey={'value'}
    labelKey="label"
    isSingleSelect={isSingleSelect}
    findValueInOptions={true}
    {...(req && { formFieldOptions: { required: `${label} Is Required` } })}
    {...props}
  />
);

const SessionDetailForm = ({ form, courseDuration, sessionId }) => {
  const { watch, setValue, trigger, formState } = form;

  // Watch form fields
  const watchStartDate = watch('startDate');
  const watchEndDate = watch('endDate');
  const watchArrivalStartDate = watch('arrivalStartDate');
  const watchArrivalEndDate = watch('arrivalEndDate');
  const watchDepartureStartDate = watch('departureStartDate');
  const watchDepartureEndDate = watch('departureEndDate');
  const travelHorizons = watch('travelHorizons');

  // Memoize parsed dates
  const parsedDates = useMemo(() => {
    const parseDate = (dateString) => {
      if (!dateString) return null;
      const [year, month, day] = dateString.split('-').map(Number);
      return new Date(Date.UTC(year, month - 1, day));
    };

    const parseDateTime = (dateTimeString) => {
      if (!dateTimeString) return null;
      const [datePart, timePart] = dateTimeString.split('T');
      const [year, month, day] = datePart.split('-').map(Number);
      const [hour = 0, minute = 0] = timePart ? timePart.split(':').map(Number) : [];
      return new Date(Date.UTC(year, month - 1, day, hour, minute));
    };

    const todayUTC = new Date();
    const today = new Date(
      Date.UTC(todayUTC.getUTCFullYear(), todayUTC.getUTCMonth(), todayUTC.getUTCDate())
    );

    // Function to get the next day of a given date
    const getNextDay = (date) => {
      if (!date) return null;
      const nextDay = new Date(date);
      nextDay.setUTCDate(date.getUTCDate() + 1);
      return nextDay;
    };

    // Function to get the day before a given date
    const getDayBefore = (date) => {
      if (!date) return null;
      const dayBefore = new Date(date);
      dayBefore.setUTCDate(date.getUTCDate() - 1);
      return dayBefore;
    };

    return {
      startDate: parseDate(watchStartDate),
      endDate: parseDate(watchEndDate),
      arrivalStartDate: parseDateTime(watchArrivalStartDate),
      arrivalEndDate: parseDateTime(watchArrivalEndDate),
      departureStartDate: parseDateTime(watchDepartureStartDate),
      departureEndDate: parseDateTime(watchDepartureEndDate),
      today,
      watchEndDateNextDay: getNextDay(parseDate(watchEndDate)),
      dayBeforeStartDate: getDayBefore(parseDate(watchStartDate)),
    };
  }, [
    watchStartDate,
    watchEndDate,
    watchArrivalStartDate,
    watchArrivalEndDate,
    watchDepartureStartDate,
    watchDepartureEndDate,
  ]);

  // Format Date object to 'YYYY-MM-DDTHH:mm'
  const toDatetimeLocal = (date) => {
    if (!date) return '';
    const pad = (num) => String(num).padStart(2, '0');
    const year = date.getUTCFullYear();
    const month = pad(date.getUTCMonth() + 1);
    const day = pad(date.getUTCDate());
    const hours = pad(date.getUTCHours());
    const minutes = pad(date.getUTCMinutes());
    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };

  const getDateOnly = (date) => {
    if (!date) return null;
    return new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate()));
  };

  // Set endDate based on startDate and courseDuration
  useEffect(() => {
    if (
      parsedDates.startDate &&
      courseDuration && // Only update endDate if it hasn't been modified by the user
      !formState.dirtyFields.endDate
    ) {
      const calculatedEndDate = new Date(parsedDates.startDate);
      calculatedEndDate.setUTCDate(
        parsedDates.startDate.getUTCDate() + Number.parseInt(courseDuration, 10)
      );

      const endDateValue = calculatedEndDate.toISOString().split('T')[0];
      setValue('endDate', endDateValue, { shouldValidate: true });
      trigger(['startDate', 'endDate']);
    }
  }, [parsedDates.startDate, courseDuration, formState.dirtyFields.endDate, setValue, trigger]);

  useEffect(() => {
    // Only proceed if endDate has been modified by the user
    if (formState.dirtyFields.endDate) {
      // Trigger validation for departure dates
      trigger(['departureStartDate', 'departureEndDate']);
    }
  }, [watchEndDate, formState.dirtyFields.endDate, trigger]);

  // Set default arrival and departure dates
  useEffect(() => {
    if (travelHorizons) {
      if (
        !watchArrivalStartDate &&
        !watchArrivalEndDate &&
        !watchDepartureStartDate &&
        !watchDepartureEndDate
      ) {
        // Create Date objects with the specified times
        const arrivalStartDateTime = new Date(parsedDates.dayBeforeStartDate);
        arrivalStartDateTime.setUTCHours(8, 0, 0, 0); // 8:00 AM UTC

        const arrivalEndDateTime = new Date(parsedDates.dayBeforeStartDate);
        arrivalEndDateTime.setUTCHours(23, 0, 0, 0); // 11:00 PM UTC

        const departureStartDateTime = new Date(parsedDates.endDate);
        departureStartDateTime.setUTCHours(15, 0, 0, 0); // 3:00 PM UTC

        const departureEndDateTime = new Date(parsedDates.watchEndDateNextDay);
        departureEndDateTime.setUTCHours(12, 0, 0, 0); // 12:00 PM (noon) UTC

        // Set form values using toDatetimeLocal
        setValue('arrivalStartDate', toDatetimeLocal(arrivalStartDateTime));
        setValue('arrivalEndDate', toDatetimeLocal(arrivalEndDateTime));
        setValue('departureStartDate', toDatetimeLocal(departureStartDateTime));
        setValue('departureEndDate', toDatetimeLocal(departureEndDateTime));
      } else {
        setValue('arrivalStartDate', toDatetimeLocal(parsedDates.arrivalStartDate));
        setValue('arrivalEndDate', toDatetimeLocal(parsedDates.arrivalEndDate));
        setValue('departureStartDate', toDatetimeLocal(parsedDates.departureStartDate));
        setValue('departureEndDate', toDatetimeLocal(parsedDates.departureEndDate));
      }
    } else {
      setValue('arrivalStartDate', '');
      setValue('arrivalEndDate', '');
      setValue('departureStartDate', '');
      setValue('departureEndDate', '');
    }
  }, [
    travelHorizons,
    parsedDates.arrivalStartDate,
    parsedDates.arrivalEndDate,
    parsedDates.departureStartDate,
    parsedDates.departureEndDate,
    parsedDates.dayBeforeStartDate,
    parsedDates.endDate,
    parsedDates.watchEndDateNextDay,
    setValue,
  ]);

  useEffect(() => {
    if (travelHorizons) {
      trigger(['arrivalStartDate', 'arrivalEndDate', 'departureStartDate', 'departureEndDate']);
    }
  }, [
    travelHorizons,
    watchArrivalStartDate,
    watchArrivalEndDate,
    watchDepartureStartDate,
    watchDepartureEndDate,
    trigger,
  ]);

  const inputPropsConfig = {
    inputProps: {
      min: '0',
      sx: {
        '&::-webkit-inner-spin-button, &::-webkit-outer-spin-button': {
          background: 'transparent',
          border: 'none',
          opacity: 1,
          display: 'block',
        },
        MozAppearance: 'textfield',
      },
    },
  };

  return (
    <>
      <div
        style={{
          width: '100%',
          padding: 10,
          fontWeight: 'bold',
          backgroundColor: '#1B76D2',
          color: 'white',
          marginBottom: 20,
        }}
      >
        <p style={{ marginBottom: 0, marginLeft: 10 }}>Session Details</p>
      </div>

      <RadioButtonGroup
        labelWeight={500}
        labelColor="#949494"
        name="status"
        type="radio"
        form={form}
        label="Session Status"
        options={[
          { value: 'draft', label: 'Draft' },
          { value: 'enrolling', label: 'Enrolling' },
          { value: 'active', label: 'Active' },
          { value: 'cut-off reached', label: 'Cut-Off Reached' },
          { value: 'complete', label: 'Complete', disabled: sessionId === 'new' },
        ]}
        validation={{ required: 'Status is required.' }}
      />

      <Divider sx={{ my: 4 }} />

      <div style={{ display: 'flex', alignItems: 'center', marginBottom: 30 }}>
        <div>
          <FormDateOnly
            label={`Start Date`}
            fieldName="startDate"
            formContext={form}
            fieldWidth="short"
            formFieldOptions={{
              required: 'Start date is required',
              validate: (startDateValue) => {
                if (!startDateValue) {
                  return 'Start date is required';
                }

                const startDate = parsedDates.startDate;
                if (!startDate) {
                  return 'Invalid start date';
                }

                const today = parsedDates.today;
                if (startDate < today) {
                  return 'Start date cannot be in the past';
                }

                return true;
              },
            }}
          />
        </div>

        <div style={{ marginLeft: 20 }}>
          <FormDateOnly
            label={`End Date`}
            fieldName="endDate"
            fieldWidth="short"
            formContext={form}
            formFieldOptions={{
              required: 'End date is required',
              validate: (endDateValue) => {
                if (!endDateValue) {
                  return 'End date is required';
                }

                const startDate = parsedDates.startDate;
                const endDate = parsedDates.endDate;

                if (!endDate) {
                  return 'Invalid end date';
                }

                if (startDate && endDate < startDate) {
                  return 'End date cannot be before the start date';
                }

                return true;
              },
            }}
          />
        </div>
      </div>

      <div style={{ display: 'flex', alignItems: 'end' }}>
        <FormInput
          label="Enrollment Cut-Off"
          fieldName="cutoffDays"
          fieldWidth="short"
          formContext={form}
          type="number"
          {...inputPropsConfig}
          formFieldOptions={{
            required: 'Enrollment Cutoff is required',
            validate: (v) => {
              if (toNumber(v) < 0) return 'Minimum value is 0';
              return true;
            },
          }}
          onBlur={() => trigger('cutoffDays')}
        />
        <p style={{ marginLeft: 10, fontWeight: 500 }}>days before start date of session</p>
      </div>

      <div style={{ marginTop: 10 }}>
        <FormCheckBox
          id={'travelhorizons'}
          label="Enable Travel Horizons"
          tooltipType="question"
          infoTooltip="Use this option to set travel arrival and departure dates for a session. If defined, attendee travel dates must be within horizon guidelines or an explanation and additional approval requirements will be required."
          fieldName={`travelHorizons`}
          formContext={form}
          preChecked={travelHorizons}
          type="checkbox"
        />
      </div>

      <div style={{ display: travelHorizons ? 'block' : 'none' }}>
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          <Box
            sx={{
              width: '34em',
              height: 220,
              borderRadius: 1,
              padding: 2,
              bgcolor: '#EEEEEE',
              marginRight: 4,
              marginBottom: 2,
            }}
          >
            <div>
              <div style={{ fontWeight: 700, marginBottom: 10 }}>Arrival Horizon</div>
              <FormDate
                label={`Start Date/Time`}
                fieldName="arrivalStartDate"
                fieldWidth="normal"
                formContext={form}
                formFieldOptions={{
                  validate: (value) => {
                    const travelCheck = watch('travelHorizons');
                    if (!travelCheck) return true;
                    if (!value) {
                      return 'Arrival Start Date is required';
                    }

                    const arrivalStartDate = parsedDates.arrivalStartDate;
                    const today = parsedDates.today;
                    const sessionStartDate = parsedDates.startDate;
                    const arrivalEndDate = parsedDates.arrivalEndDate;

                    if (!arrivalStartDate) {
                      return 'Invalid arrival start date';
                    }

                    // Get date-only versions
                    const arrivalStartDateOnly = getDateOnly(arrivalStartDate);
                    const sessionStartDateOnly = getDateOnly(sessionStartDate);
                    const todayOnly = getDateOnly(today);

                    // Check if arrivalStartDate is in the past
                    if (arrivalStartDateOnly < todayOnly) {
                      return 'Arrival Start Date cannot be in the past';
                    }

                    // Check if arrivalStartDate is after the session start date
                    if (sessionStartDateOnly && arrivalStartDateOnly > sessionStartDateOnly) {
                      return 'Arrival Start Date cannot be after the Session Start Date';
                    }

                    // Check if arrivalStartDate is after arrivalEndDate
                    if (arrivalEndDate) {
                      const arrivalEndDateOnly = getDateOnly(arrivalEndDate);
                      if (arrivalStartDateOnly > arrivalEndDateOnly) {
                        return 'Arrival Start Date cannot be after Arrival End Date';
                      }
                    }

                    return true;
                  },
                }}
                inputProps={{
                  min: parsedDates.today.toISOString().split('T')[0], // Set minimum date as today
                }}
              />
            </div>
            <div>
              <FormDate
                label={`End Date/Time`}
                fieldName="arrivalEndDate"
                fieldWidth="normal"
                formContext={form}
                formFieldOptions={{
                  validate: (value) => {
                    const travelCheck = watch('travelHorizons');
                    if (!travelCheck) return true;

                    if (!value) {
                      return 'Arrival End Date is required';
                    }

                    const arrivalStartDate = parsedDates.arrivalStartDate;
                    const arrivalEndDate = parsedDates.arrivalEndDate;
                    const sessionStartDate = parsedDates.startDate;

                    if (!arrivalEndDate) {
                      return 'Invalid arrival end date';
                    }

                    // Get date-only versions
                    const arrivalEndDateOnly = getDateOnly(arrivalEndDate);
                    const sessionStartDateOnly = getDateOnly(sessionStartDate);

                    // Check if arrivalEndDate is before arrivalStartDate
                    if (arrivalStartDate) {
                      const arrivalStartDateOnly = getDateOnly(arrivalStartDate);
                      if (arrivalEndDateOnly < arrivalStartDateOnly) {
                        return 'Arrival End Date cannot be before Arrival Start Date';
                      }
                    }

                    // Check if arrivalEndDate is after the session start date
                    if (sessionStartDateOnly && arrivalEndDateOnly > sessionStartDateOnly) {
                      return 'Arrival End Date cannot be after the Session Start Date';
                    }

                    return true;
                  },
                }}
              />
            </div>
          </Box>

          <Box
            sx={{
              width: '34em',
              height: 220,
              borderRadius: 1,
              padding: 2,
              bgcolor: '#EEEEEE',
            }}
          >
            <div>
              <div style={{ fontWeight: 700, marginBottom: 10 }}>Departure Horizon</div>
              <FormDate
                label={`Start Date/Time`}
                fieldName="departureStartDate"
                fieldWidth="normal"
                formContext={form}
                formFieldOptions={{
                  validate: (value) => {
                    const travelCheck = watch('travelHorizons');
                    if (!travelCheck) return true;

                    if (!value) {
                      return 'Departure Start date is required';
                    }

                    const departureStartDate = parsedDates.departureStartDate;
                    const arrivalEndDate = parsedDates.arrivalEndDate;
                    const endDate = parsedDates.endDate;

                    if (!departureStartDate) {
                      return 'Invalid departure start date';
                    }

                    // Check if departureStartDate is before arrivalEndDate
                    if (arrivalEndDate && departureStartDate < arrivalEndDate) {
                      return 'Departure Start Date cannot be before Arrival End Date';
                    }

                    // Check if departureStartDate is before session end date
                    if (endDate && departureStartDate < endDate) {
                      return 'Departure Start Date cannot be before Session End Date';
                    }

                    return true;
                  },
                }}
              />
            </div>
            <div>
              <FormDate
                label={`End Date/Time`}
                fieldName="departureEndDate"
                fieldWidth="normal"
                formContext={form}
                formFieldOptions={{
                  validate: (value) => {
                    const travelCheck = watch('travelHorizons');
                    if (!travelCheck) return true;

                    if (!value) {
                      return 'Departure End date is required';
                    }

                    const departureStartDate = parsedDates.departureStartDate;
                    const departureEndDate = parsedDates.departureEndDate;

                    if (!departureEndDate) {
                      return 'Invalid departure end date';
                    }

                    // Check if departureEndDate is before departureStartDate
                    if (departureStartDate && departureEndDate < departureStartDate) {
                      return 'Departure End Date cannot be before Departure Start Date';
                    }

                    return true;
                  },
                }}
              />
            </div>
          </Box>
        </div>
      </div>

      <Divider sx={{ mt: 4 }} />

      <div style={{ marginBottom: 20 }}>
        <FormInput
          label="Session Name"
          fieldName="name"
          fieldWidth="normal"
          formContext={form}
          defaultValue=""
          formFieldOptions={{
            required: 'This field is required.',
          }}
        />
      </div>

      <SessionMulti
        sx={{ mb: 4 }}
        fieldName="billingCategory"
        form={form}
        fieldWidth="normal"
        defaultValue="none"
        label="Billing Category"
        optionsLoader={api.getBilling}
      />

      <div style={{ width: '60%', marginBottom: 20 }}>
        <FormWysiwyg
          tag="textarea"
          label="Session Description"
          formContext={form}
          fieldName="description"
        />
      </div>

      <div>
        <SessionMulti
          sx={{ mb: 4 }}
          fieldName="trainingLocation"
          form={form}
          label="Training Location"
          optionsLoader={api.getTrainingPrograms}
          req
        />
      </div>

      <SessionMulti
        sx={{ mb: 4 }}
        fieldName="category"
        form={form}
        label="Category / Department"
        optionsLoader={api.getCategories}
        disabled={true}
        req
      />

      <SessionMulti
        fieldName="program"
        form={form}
        label="Program(s)"
        placeholder={'Select Program'}
        optionsLoader={api.getPrograms}
        isSingleSelect={false}
      />

      <div style={{ marginTop: 10, marginBottom: 20 }}>
        <FormCheckBoxGroup
          id="enrollment-approval"
          label="Enrollment Approval"
          name="approvers"
          form={form}
          formFieldOptions={{
            required: 'At least one approver is required.',
          }}
          options={[
            { label: 'Point of Contact', value: 'point-of-contact' },
            { label: 'Program Coordinator', value: 'program-coordinator' },
          ]}
        />
      </div>
    </>
  );
};

export default SessionDetailForm;
