import { useQuery, useQueryClient } from '@tanstack/react-query';
import moment from 'moment';
import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import logger from '../../logger';
import { navigateToPluginPage } from '../../store/rootReducer';
import sessionApi, { getSessionDetails } from '../api/sessionApi';
import { ItrsCourseConstants, ItrsSessionConstants } from '../definitions';
import { useSelector } from 'react-redux';
import { selectHasPrivileges } from '../../store/rootSelectors';
import useArray from '../../shared/util/hooks/useArray';

const enrollmentColumns = [
  { label: 'Attendee Type', valueField: 'attendeeType', sortable: true },
  { label: 'Pending Requests', valueField: 'pendingRequests', sortable: true },
  { label: 'Approved', valueField: 'approved', sortable: true },
  { label: 'Total Enrolled', valueField: 'totalEnrolled', sortable: true },
  { label: 'Max. Seats', valueField: 'maxSeats', sortable: true },
  { label: 'Travel Requests Submitted', valueField: 'travelRequestsSubmitted', sortable: true },
  { label: 'Travel Confirmed', valueField: 'travelConfirmed', sortable: true },
];

const trainingRequestsColumns = [
  { label: 'Name', valueField: 'name', sortable: true },
  { label: 'Local Union', valueField: 'localUnion', sortable: true },
  { label: 'Date Submitted', valueField: 'dateSubmitted', sortable: true },
  { label: 'Attendee Type', valueField: 'attendeeType', sortable: true },
  { label: 'Status', valueField: 'status', sortable: true },
];

export default function useSessionDetails(id, unionSearchField, cancelEnrollmentAction) {
  const queryClient = useQueryClient();
  const [confirmCompletionModalProps, setConfirmCompletionModalProps] = useState({});
  const queryKey = ['itrs-session-details', { id, unionSearchField }];
  const {
    data: {
      course = {},
      session = {},
      instructors = [],
      assistants = [],
      students = [],
      trainingRequests = [],
      enrollments = [],
    } = {},
    isLoading,
    isError,
    error,
  } = useQuery({
    queryKey,
    queryFn: () => getSessionDetails(id, unionSearchField),
    enabled: !!id,
  });
  const invalidate = () =>
    queryClient.invalidateQueries({ queryKey: ['itrs-session-details', { id, unionSearchField }] });
  const dispatch = useDispatch();
  const hasTravelPrivileges = useSelector(selectHasPrivileges('itrs.travel'));
  const hasTrainingPrivileges = useSelector(selectHasPrivileges('itrs.training'));
  const { startDate, endDate } = session;
  const { dateTitle, fullRange } = useMemo(() => {
    if (!startDate || !endDate) return { dateTile: '', fullRange: '' };
    const s = moment(startDate).utc();
    const e = moment(endDate).utc();
    let title = '';
    if (s.isSame(e, 'day')) {
      // Same day
      title = s.format('MMMM D, YYYY');
    } else if (s.isSame(e, 'month') && s.isSame(e, 'year')) {
      // Same month and year
      title = `${s.format('MMMM')} ${s.format('D')}-${e.format('D')}, ${s.format('YYYY')}`;
    } else if (s.isSame(e, 'year')) {
      // Same year, different months
      title = `${s.format('MMMM D')} - ${e.format('MMMM D')}, ${s.format('YYYY')}`;
    } else {
      // Different years
      title = `${s.format('MMMM D, YYYY')} - ${e.format('MMMM D, YYYY')}`;
    }
    const fullRange = `${s.format('MM/DD/YYYY')} - ${e.format('MM/DD/YYYY')}`;
    return { dateTitle: title, fullRange };
  }, [startDate, endDate]);

  const editSession = useCallback(() => {
    dispatch(
      navigateToPluginPage([
        ItrsSessionConstants.pluginId,
        ItrsSessionConstants.editorPageId,
        course.id,
        id,
      ])
    );
  }, [dispatch, id, course.id]);

  const deleteSession = useCallback(async () => {
    await sessionApi.removeRecord(id);
    dispatch(
      navigateToPluginPage([ItrsCourseConstants.pluginId, ItrsCourseConstants.defaultPageId])
    );
  }, [dispatch]);

  const handleApproval = useCallback(
    async (context) => {
      try {
        await sessionApi.approveTrainingRequest(context.enrollmentId, 'APPROVED');
        await new Promise((r) => setTimeout(r, 2000));
        queryClient.invalidateQueries({ queryKey: ['itrs-session-details', { id }] });
        invalidate();
      } catch (error) {
        logger.error(error);
      }
    },
    [id, invalidate]
  );

  const handleDeny = useCallback(
    async (context, denialReason) => {
      try {
        await sessionApi.approveTrainingRequest(context.enrollmentId, 'DENIED', denialReason);
        await new Promise((r) => setTimeout(r, 2000));
        queryClient.invalidateQueries({ queryKey: ['itrs-session-details', { id }] });
        invalidate();
      } catch (error) {
        logger.error(error);
      }
    },
    [id, invalidate]
  );

  const viewTravelReq = useMemo(
    () => ({
      icon: 'fa fa-search',
      label: `View Travel Request`,
      disabled: (context) => !context.travelRequest,
      onClick: async (context) => {
        try {
          if (!context.travelRequest) throw new Error('No travel request found');
          dispatch(
            navigateToPluginPage([
              ItrsSessionConstants.pluginId,
              ItrsSessionConstants.travelConfirmationPageId,
              context.travelRequest.id,
            ])
          );
        } catch (error) {
          logger.error(error);
        }
      },
    }),
    [id]
  );

  const attendeesColumns = [
    { label: 'Name', valueField: 'name', sortable: true },
    { label: 'Local Union', valueField: 'localUnion', sortable: true },
  ];

  if (hasTrainingPrivileges) {
    attendeesColumns.push({
      label: 'Status',
      valueField: 'status',
      sortable: true,
      renderer: (item) => {
        const st = item?.status;
        switch (st) {
          case 'CANCELLED': {
            return <span style={{ color: '#DC3545' }}>{'Cancelled'}</span>;
          }
          case 'COMPLETED': {
            return <span style={{ fontWeight: 'bold' }}>{'Completed'}</span>;
          }
          case 'APPROVED': {
            return 'Approved';
          }
          case 'DENIED': {
            return 'Denied';
          }
          // No default
        }
        return 'Unknown';
      },
    });
  }

  if (hasTravelPrivileges) {
    attendeesColumns.push({
      label: 'Travel Status',
      valueField: 'status',
      sortable: true,
      renderer: (item) => {
        const req = item.travelRequest;
        const notSubmitted = !req;
        const submitted =
          req && (!req.travelConfirmation || req.travelConfirmation.status !== 'confirmed');
        const confirmed = req?.travelConfirmation?.status === 'confirmed';

        return (
          <span>
            {notSubmitted && (
              <span>
                Travel Request NOT Submitted
                <i style={{ marginLeft: '0.5em', color: '#DC3545' }} className="fa fa-plane-up"></i>
              </span>
            )}
            {submitted && (
              <span>
                Travel Request Submitted
                <i style={{ marginLeft: '0.5em', color: '#FFC107' }} className="fa fa-plane-up"></i>
              </span>
            )}
            {confirmed && (
              <span>
                Travel Request Confirmed
                <i style={{ marginLeft: '0.5em', color: '#28A745' }} className="fa fa-plane-up"></i>
              </span>
            )}
          </span>
        );
      },
    });
  }

  const markUserCompleted = useMemo(
    () => ({
      icon: 'fa fa-solid fa-circle-check',
      label: `Mark Complete`,
      disabled: (context) => context.status === 'COMPLETED',
      onClick: async ({ fullName, enrollmentId }) => {
        try {
          setConfirmCompletionModalProps({
            sessionId: id,
            fullName,
            enrollmentId,
            invalidate,
          });
        } catch (error) {
          logger.error(error);
        }
      },
    }),
    [id, setConfirmCompletionModalProps, invalidate]
  );
  const studentActions = useArray(viewTravelReq, cancelEnrollmentAction, markUserCompleted);

  return {
    attendeesColumns,
    confirmCompletionModalProps,
    course,
    dateTitle,
    deleteSession,
    editSession,
    enrollmentColumns,
    enrollments,
    error,
    fullRange,
    handleApproval,
    handleDeny,
    instructors,
    invalidate,
    isError,
    isLoading,
    queryClient,
    session,
    studentActions,
    students,
    assistants,
    trainingRequests,
    trainingRequestsColumns,
    viewTravelReq,
  };
}
