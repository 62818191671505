import { useMemo, useState } from 'react';
import logger from '../../logger';
import { cancelEnrollment } from '../api/sessionApi';

export default function useCancelModal(sessionId) {
  const [openCancelModal, setOpenCancelModal] = useState(false);
  const [selectedStudent, setSelectedStudent] = useState(null);

  const openModal = (context) => {
    setSelectedStudent(context);
    setOpenCancelModal(true);
  };

  const closeModal = () => {
    setOpenCancelModal(false);
    setSelectedStudent(null);
  };

  const handleCancelEnrollment = async (reason, note) => {
    try {
      await cancelEnrollment(sessionId, selectedStudent.enrollmentId, reason, note);
      closeModal();
    } catch (error) {
      logger.error(error);
    }
  };
  const cancelEnrollmentAction = useMemo(
    () => ({ icon: 'fa fa-ban', label: 'Cancel Enrollment', disabled: false, onClick: openModal }),
    []
  );

  return {
    openCancelModal,
    selectedStudent,
    openModal,
    closeModal,
    cancelEnrollmentAction,
    handleCancelEnrollment,
  };
}
